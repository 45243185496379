import React from "react"
import classNames from 'classnames'
import { Link } from 'gatsby'

const ComparedBadgeComponent = ({ content }) => {
  return (
    <div className={classNames('badges-wrapper',
      { "section-compared": content.section === "compared" },
      { "section-footer": content.section === "footer" },
      { "section-header": content.section === "header" },
    )}>
      <div className="badge-holder">
        {
          content.link ?
            <div className="badge-flex fall-edition">
              {
                content.FallBadges.map((LinkBadge, i) => {
                  return (
                    <div className="badge-item" key={i}>
                      <Link to="https://www.g2.com/products/billsby/reviews" target="_blank" rel="noopener noreferrer">
                        <img src={LinkBadge.img} className="badge-logo" alt={LinkBadge.altText} />
                      </Link>
                    </div>
                  )
                })
              }
            </div>
            :
            <div className="badge-flex fall-edition">
              {
                content.FallBadges.map((BadgeImage, j) => {
                  return (
                    <div className="badge-item" key={j}>
                      <img src={BadgeImage.img} className="badge-logo" alt={BadgeImage.altText} />
                    </div>
                  )
                })

              }
            </div>
        }
        {
          content.SmallBussinessBadge ?
            <div className="badge-flex sb-edition">
              {
                content.SmallBussinessBadge.map((LinkBadge, k) => {
                  return (
                    <div className="badge-item" key={k}>
                      <Link to="https://www.g2.com/products/billsby/reviews" target="_blank" rel="noopener noreferrer">
                        <img src={LinkBadge.img} className="badge-logo" alt={LinkBadge.altText} />
                      </Link>
                    </div>
                  )
                })
              }
            </div>
            : ''
        }
      </div>
    </div>
  )
}

export default ComparedBadgeComponent